<template>
	<div class="yscontainer" v-loading='loading'>
		<!-- :rules="rules" -->
		<el-form :model="ruleForm" ref="ruleForm" label-width="150px">
			<el-card>
				<span>移动端客服工作台&nbsp&nbsp&nbsp 可以微信扫码登录</span>

				<el-popover placement="bottom" width="250" trigger="click" @show='showImgsrc' @hide='hideImgsrc'>
					<div style="display: flex;justify-content: center;">
						<div class="code" id="qrcode" ref="qrcode"></div>
					</div>
					<span style="cursor: pointer;color:#409EFF;" slot="reference">移动端客服工作台</span>
				</el-popover>

				<span>进行客服沟通</span>
			</el-card>
			<el-card class="box-card" style="margin:10px 0 10px 0" v-if="PCmallId == 15">
				<div slot="header" class="clearfix"><span>客服系统</span></div>
				<div class="content">
					<el-form-item label="客服系统：">
						<el-radio-group v-model="ruleForm.CustomerServiceType">
							<el-radio :label="0">店客易客服系统</el-radio>
							<el-radio :label="1">Udesk客服系统</el-radio>
						</el-radio-group>
					</el-form-item>
				</div>
			</el-card>
			<el-card class="box-card" style="margin:10px 0 10px 0">
				<div slot="header" class="clearfix"><span>会话分配</span></div>
				<div class="content">
					<el-form-item label="自动分配：">
						<el-switch v-model="ruleForm.CustomerServiceAllocationType" style='margin-left:10px;'></el-switch>
					</el-form-item>
					<div style="font-size: 13px;color: #999;padding-left: 148px;">开启后，自动将待接入会话分配给各客服，待接入列表中只展示分配给自己的会话</div>
				</div>
			</el-card>

			<el-card class="box-card" style="margin:10px 0 10px 0">
				<div slot="header" class="clearfix"><span>客服接待设置</span></div>
				<div class="content">
					<el-form-item label="熟客优先：">
						<el-switch v-model="ruleForm.LastCustomerServiceType"></el-switch>
						<div style="font-size: 13px;color: #999;">开启后，客户会话优先分配给上次接待的客服</div>
					</el-form-item>
					<el-form-item label-width="0px">
						<div style="margin-bottom: 10px;"><label style="color:#606266;">客服回复消息后，客户未回复</label>
							<el-switch v-model="ruleForm.EnableCustomerUnansweredType" style='margin-left:10px;'></el-switch>
						</div>
						<el-form-item v-if='ruleForm.EnableCustomerUnansweredType' label="" label-width="150px" :prop="ruleForm.EnableCustomerUnansweredType?'CustomerUnansweredTime':'nocheck'"
						 :rules='ruleForm.EnableCustomerUnansweredType?rules.CustomerUnansweredTime:rules.nocheck'>
							<div><span>超过</span>

								<el-input @input='ruleForm.CustomerUnansweredTime=validateInt(ruleForm.CustomerUnansweredTime)' v-model="ruleForm.CustomerUnansweredTime"
								 clearable style="width:120px;margin:0 10px;">
								</el-input>
								<span>分钟，自动结束会话</span>
							</div>
						</el-form-item>
						<div style="font-size: 13px;color: #999;padding-left: 148px;">开启后，已接入会话，客服回复后，超时客户未回复，系统将自动结束会话</div>
					</el-form-item>
					<el-form-item label-width="0px">
						<!--  -->
						<div style="margin-bottom: 10px;"><label style="color:#606266;">客户回复消息后，客服未回复或接入会话</label>
							<el-switch v-model="ruleForm.EnableUnansweredType" style='margin-left:10px;'></el-switch>
						</div>
						<div v-if='ruleForm.EnableUnansweredType'>
							<el-form-item label="" label-width="150px">
								<el-radio-group v-model="ruleForm.UnansweredType">
									<el-radio :label="1">重新分配</el-radio>
									<el-radio :label="0">自动结束</el-radio>
								</el-radio-group>
							</el-form-item>
						</div>
						<el-form-item v-if='ruleForm.EnableUnansweredType' label="" label-width="150px" :prop="ruleForm.EnableUnansweredType?'UnansweredTime':'nocheck'"
						 :rules='ruleForm.EnableUnansweredType?rules.UnansweredTime:rules.nocheck'>
							<div><span>超过</span>
								<el-input @input='ruleForm.UnansweredTime=validateInt(ruleForm.UnansweredTime)' v-model="ruleForm.UnansweredTime"
								 clearable style="width:120px;margin:0 10px;">
								</el-input>
								<span>分钟，{{ruleForm.UnansweredType==1 ?'重新分配':'自动结束'}}会话</span>
							</div>
						</el-form-item>
						<div style="font-size: 13px;color: #999;padding-left: 148px;">{{ruleForm.CustomerServiceAllocationType?'开启重新分配，待接入或已接入的会话超时未被客服接入或未回复将重新分配会话给其他客服；开启自动结束，已接入的会话客户回复消息后，客服超时未回复客户将自动结束会话':'开启重新分配或自动结束后，已接入的会话客户回复消息后，客服超时回复客户，会话将重新转为待接入或结束会话'}}</div>
					</el-form-item>

				</div>
			</el-card>
			<el-card class="box-card" style="margin:10px 0 40px 0">
				<div slot="header" class="clearfix"><span>消息通知设置</span></div>
				<div class="content">
					<el-form-item label="公众号消息通知：">
						<el-switch v-model="ruleForm.EnableMPMessage"></el-switch>
					</el-form-item>
					<div style="font-size: 13px;color: #999;padding-left: 148px;">开启后，客服回复客户消息，若客户离线，将通过公众号模板消息通知客户，5分钟内回复多条，仅通知一次。客户必须关注公众号才能被通知</div>
				</div>

				<div class="content">
					<el-form-item label="桌面通知：">
						<el-switch v-model="ruleForm.DesktopNotice"></el-switch>
					</el-form-item>
					<div style="font-size: 13px;color: #999;padding-left: 148px;">开启后，当有新客户消息时，会收到浏览器弹出的通知提醒。
						<el-popover placement="right" width="300" trigger="hover">
							<div style="display: flex;justify-content: center;background: #F5F5F5;align-items: center;">
								<img src="http://cdn.dkycn.cn/Images/maifengle/googlelogoImg.jpg" style="width:50px;height: 50px;" />
								<div>
									<div>张三</div>
									<div>kf.qidian.shop</div>
									<div>在吗？最近有没有活动？</div>
								</div>
								<img src="http://cdn.dkycn.cn/Images/maifengle/setServicelogo.jpg" style="width:50px;height: 50px;" />
							</div>

							<span style="cursor: pointer;color:#409EFF;" slot="reference">样式浏览</span>
						</el-popover>
					</div>
				</div>
			</el-card>
		</el-form>
		<el-card class="box-card footer">
			<el-button style="width:240px;position: relative;bottom: 20px;right: 190px;" type="primary" :loading="btnLoading"
			 @click="saveSupiler('ruleForm')">保存</el-button>
		</el-card>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2'
	import config from '@/config/index'
	import {
		xjmallplatformGateway,
		customSettingIndex,
		customSettingSave
	} from '@/api/TurnTomySelf';
	import {
		XJChatGetLoginCert
	} from '@/api/login.js';
	export default {
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			};
			var TimeShowTextreplyNoCheck = (rule, value, callback) => {

				if (value > 1440 || value < 3) {
					return callback(new Error('请输入3-1440分钟'));
				} else {
					return callback();
				}
			};
			var TimeShowTextreplyCallCheck = (rule, value, callback) => {

				if (value > 1440 || value < 3) {
					return callback(new Error('请输入3-1440分钟'));
				} else {
					return callback();
				}
			};
			return {
				imgUrl: config.IMG_BASE,
				PCmallId: null,
				loading: false,
				btnLoading: false,
				ruleForm: {
					LastCustomerServiceType: false,
					EnableMPMessage: false,
					CustomerServiceType: 0,
					CustomerServiceAllocationType: false,
					EnableUnansweredType: false,
					UnansweredType: 0,
					UnansweredTime: '',
					EnableCustomerUnansweredType: 0,
					CustomerUnansweredTime: '',
					DesktopNotice: false,
					companyId: ''
				},
				rules: {
					CustomerUnansweredTime: [{
						required: true,
						validator: TimeShowTextreplyNoCheck,
						trigger: 'blur'
					}],
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
					UnansweredTime: [{
						required: true,
						validator: TimeShowTextreplyCallCheck,
						trigger: 'blur'
					}],
				},
				cert: ''
				// rules:[],
			}

		},
		methods: {
			async getCert(callback){
				
				try{
					const res = await XJChatGetLoginCert();
					this.cert = res.LoginCert;
					callback()
				}catch(err){
					// this.$message.error('登录信息获取失败')
				}
				
				
			},
			showImgsrc() {
				
				this.getCert(()=>{
					var urlHost = 'http://kftest.qidian.shop';
					if (process.env.NODE_ENV == 'production' && process.env.VUE_APP_CURRENTMODE !== 'stage'){
						urlHost = 'https://kf.dkycn.cn';
					}
					
					var text = urlHost + '/page/#/wapList?companyId='+this.ruleForm.companyId+'&cert='+this.cert;
					console.log(text)
					
					this.qrcode = new QRCode('qrcode', {
						// text: process.env.NODE_ENV == 'production' ? (process.env.VUE_APP_CURRENTMODE == 'stage' ?
						// 		'https://kftest.qidian.shop/page/#/login?companyId=' + this.ruleForm.companyId :
						// 		'https://kf.dkycn.cn/page/#/login?companyId=' + this.ruleForm.companyId) : 'https://kftest.qidian.shop/page/#/login?companyId=' +
						// 	this.ruleForm.companyId,
						// text: "https://kftest.qidian.shop/page/#/login?companyId=" + this.ruleForm.companyId,
						text: text,
						width: 150,
						height: 150,
					});
				})

			},
			hideImgsrc() {
				document.getElementById('qrcode').innerHTML = "";
			},
			// 验证整数
			validateInt(num) {
				num = num.replace(/^0*(0\.|[1-9])/, '$1');
				num = num.replace(/[^\d]/g, "");
				this.$forceUpdate();
				return num
			},
			async GetxjmallplatformGateway() {
				try {
					this.loading = true
					let data = {
						ApiPath: '/customerservice/setting'
					}
					let result = await xjmallplatformGateway(data)
					if (result.IsSuccess) {
						this.ruleForm = result.Result
						this.ruleForm.LastCustomerServiceType = result.Result.LastCustomerServiceType == 1 ? false : true
						this.ruleForm.EnableMPMessage = result.Result.EnableMPMessage
						this.ruleForm.CustomerServiceAllocationType = result.Result.CustomerServiceAllocationType == 1 ? true : false
						this.ruleForm.CustomerUnansweredTime = result.Result.CustomerUnansweredTime
						this.ruleForm.EnableCustomerUnansweredType = result.Result.EnableCustomerUnansweredType == 1 ? true : false
						this.ruleForm.EnableUnansweredType = result.Result.EnableUnansweredType == 1 ? true : false
						this.ruleForm.UnansweredTime = result.Result.UnansweredTime
						this.ruleForm.UnansweredType = result.Result.UnansweredType
						this.ruleForm.DesktopNotice = result.Result.DesktopNotice
						this.ruleForm.companyId = result.Result.CompanyId

					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			// 保存
			saveSupiler(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.btnLoading = true
						try {
							// this.ruleForm.EnableMPMessage = result.Result.EnableMPMessage
							// this.ruleForm.CustomerServiceAllocationType  = result.Result.CustomerServiceAllocationType == 1?true:false
							// this.ruleForm.CustomerUnansweredTime = result.Result.CustomerUnansweredTime
							// this.ruleForm.EnableCustomerUnansweredType =  result.Result.EnableCustomerUnansweredType
							// this.ruleForm.EnableUnansweredType = result.Result.EnableUnansweredType==1 ? true:false
							// this.ruleForm.UnansweredTime = result.Result.UnansweredTime
							// this.ruleForm.UnansweredType = result.Result.UnansweredType
							let data = {
								ApiPath: "/customerservice/setting-update",
								RequestBody: {
									LastCustomerServiceType: this.ruleForm.LastCustomerServiceType ? 0 : 1,
									EnableMPMessage: this.ruleForm.EnableMPMessage,
									CustomerServiceAllocationType: this.ruleForm.CustomerServiceAllocationType ? 1 : 0,
									CustomerUnansweredTime: this.ruleForm.CustomerUnansweredTime,
									EnableCustomerUnansweredType: this.ruleForm.EnableCustomerUnansweredType ? 1 : 0,
									EnableUnansweredType: this.ruleForm.EnableUnansweredType ? 1 : 0,
									UnansweredTime: this.ruleForm.UnansweredTime,
									UnansweredType: this.ruleForm.UnansweredType,
									DesktopNotice: this.ruleForm.DesktopNotice
								}
							}
							let result = await xjmallplatformGateway(data)
							if (this.PCmallId == 15) {
								let data1 = {
									CustomerServiceType: this.ruleForm.CustomerServiceType
								}
								let result1 = await customSettingSave(data1)
								if (result.IsSuccess && result1.IsSuccess) {
									this.$message({
										showClose: true,
										type: 'success',
										message: '操作成功!'
									});
									this.GetxjmallplatformGateway()
									this.getConfigInfo()
								}
							} else {
								if (result.IsSuccess) {
									this.$message({
										showClose: true,
										type: 'success',
										message: '操作成功!'
									});
									this.GetxjmallplatformGateway()
									// this.getConfigInfo()
								}
							}


						} catch (err) {
							this.btnLoading = false
						} finally {
							this.btnLoading = false
						}
					} else {
						this.$common.completeFormInformation('请完善客服设置信息', 'is-error')
						return false;
					}
				});
			},
			async getConfigInfo() {
				try {
					let result = await customSettingIndex()
					if (result.IsSuccess) {
						this.ruleForm.CustomerServiceType = result.Result.CustomerServiceType
					}
				} catch (err) {

				} finally {

				}
			}
		},
		created() {
			this.PCmallId = window.localStorage.getItem('PCMallId')
			this.GetxjmallplatformGateway()
			if (this.PCmallId == 15) {
				this.getConfigInfo()
			}
		}
	}
</script>

<style lang="less" scoped>
	.yscontainer {
		width: 100%;
		margin-bottom: 100px;
		// overflow: hidden;

		::v-deep .el-input {
			width: 300px;
		}

		::v-deep .el-form-item {
			margin-bottom: 8px;
		}

		.footer {
			background-color: #fff;
			width: 100%;
			margin-right: 30px;
			height: 80px;
			text-align: center;
			line-height: 80px;
			position: fixed;
			bottom: 0px;
			z-index: 10;
		}
	}
</style>
